<template>
  <div class="layers-icon" v-bind:style="iconStyle">
    <div v-if="type === 'point'" class="point-icon" v-bind:style="point1Style"></div>
    <div v-if="type === 'point'" class="point-icon" v-bind:style="point2Style"></div>
    <div v-if="type === 'point'" class="point-icon" v-bind:style="point3Style"></div>
    <div v-if="type === 'point'" class="point-icon" v-bind:style="point4Style"></div>
    <div v-if="type === 'linestring'" class="linestring-icon" v-bind:style="linestringStyle"></div>
    <div v-if="type === 'linestring'" class="linestring-dot-icon" v-bind:style="linestringDot1Style"></div>
    <div v-if="type === 'linestring'" class="linestring-dot-icon" v-bind:style="linestringDot2Style"></div>
  </div>
</template>

<script>
import Pattern from '@bingo_soft/mapmanager/src/Infrastructure/Util/Pattern'

export default {
  name: 'LayersIcon',
  components: {},
  props: {
    type: String,
    properties: [Array, Object],
    async: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    properties: {
      handler (value) {
        this.setStyles()
      },
      deep: true
    }
  },
  data () {
    return {
      iconStyle: {},
      point1Style: {
        top: '2px',
        left: '2px'
      },
      point2Style: {
        top: '2px',
        right: '2px'
      },
      point3Style: {
        bottom: '2px',
        left: '2px'
      },
      point4Style: {
        bottom: '2px',
        right: '2px'
      },
      linestringStyle: {},
      linestringDot1Style: {},
      linestringDot2Style: {}
    }
  },
  methods: {
    setStyles () {
      this.resetIcon()
      if (this.type === 'point') {
        this.buildPointIcon()
      } else if (this.type === 'linestring') {
        this.buildLinestringIcon()
      } else if (this.type === 'polygon') {
        this.buildPolygonIcon()
      }
    },
    buildPointIcon () {
      let color, type, icon
      for (let i in this.properties) {
        if (this.properties[i].name === 'color') {
          color = this.properties[i].value
        }
        if (this.properties[i].name === 'marker_type') {
          type = this.properties[i].value
        }
        if (this.properties[i].name === 'icon_file') {
          icon = this.properties[i].value
        }
      }
      if (type === 'simple_point') {
        this.$set(this.iconStyle, 'border', `1px solid ${color}`)
        this.$set(this.point1Style, 'background-color', color)
        this.$set(this.point2Style, 'background-color', color)
        this.$set(this.point3Style, 'background-color', color)
        this.$set(this.point4Style, 'background-color', color)
      } else {
        if (typeof icon !== 'undefined' && icon.hasOwnProperty('guid')) {
          this.$set(this.iconStyle, 'background-image', `url(${this.$config.api}/files/mapeditor/images/${icon.guid}.${icon.extension})`)
          this.$set(this.iconStyle, 'background-repeat', 'no-repeat')
          this.$set(this.iconStyle, 'background-size', '18px')
        }
      }
    },
    buildLinestringIcon () {
      let color, strokeStyle
      for (let i in this.properties) {
        if (this.properties[i].name === 'color') {
          color = this.properties[i].value
        }
        if (this.properties[i].name === 'stroke_style') {
          strokeStyle = this.properties[i].value
        }
      }

      this.$set(this.iconStyle, 'border', `1px solid ${color}`)
      this.$set(this.iconStyle, 'border-top', `1px solid ${color}`)
      this.$set(this.iconStyle, 'border-bottom', `1px solid ${color}`)
      this.$set(this.linestringStyle, 'background-color', color)
      if (typeof strokeStyle !== 'undefined' && strokeStyle.hasOwnProperty('pattern') && strokeStyle.pattern.length) {
        this.$set(this.linestringDot1Style, 'background-color', '#FFFFFF')
        this.$set(this.linestringDot2Style, 'background-color', '#FFFFFF')
        this.$set(this.linestringDot1Style, 'left', '4px')
        this.$set(this.linestringDot2Style, 'left', '10px')
      }
    },
    buildPolygonIcon () {
      let color, backgroundColor, fillStyle
      let opts = this.properties.reduce((obj, item) => {
        obj[item.name] = item.value
        return obj
      }, {})
      for (let i in this.properties) {
        if (this.properties[i].name === 'color') {
          color = this.properties[i].value
        }
        if (this.properties[i].name === 'background_color') {
          backgroundColor = this.properties[i].value
        }
        if (this.properties[i].name === 'fill_style') {
          fillStyle = this.properties[i].value
        }
      }
      this.$set(this.iconStyle, 'border', `1px solid ${color}`)
      this.$set(this.iconStyle, 'background-color', backgroundColor)
      if (typeof fillStyle !== 'undefined' && fillStyle !== 'empty') {
        this.$set(this.iconStyle, 'background-image', `url(${Pattern.getPatternDataURI(fillStyle, opts)})`)
        this.$set(this.iconStyle, 'background-repeat', 'repeat')
      } else {
        this.$delete(this.iconStyle, 'background-image')
        this.$delete(this.iconStyle, 'background-repeat')
        this.$delete(this.iconStyle, 'border')
      }
    },
    resetIcon () {
      // точка
      this.$delete(this.iconStyle, 'background-image')
      this.$delete(this.iconStyle, 'background-repeat')
      this.$delete(this.iconStyle, 'background-size')
      this.$delete(this.iconStyle, 'border')
      this.$delete(this.point1Style, 'background-color')
      this.$delete(this.point2Style, 'background-color')
      this.$delete(this.point3Style, 'background-color')
      this.$delete(this.point4Style, 'background-color')
      // линия
      this.$delete(this.iconStyle, 'border')
      this.$delete(this.iconStyle, 'border-top')
      this.$delete(this.iconStyle, 'border-bottom')
      this.$delete(this.linestringStyle, 'background-color')
      this.$delete(this.linestringDot1Style, 'background-color')
      this.$delete(this.linestringDot2Style, 'background-color')
      this.$delete(this.linestringDot1Style, 'left')
      this.$delete(this.linestringDot2Style, 'left')
      // полигон
      this.$delete(this.iconStyle, 'background-image')
      this.$delete(this.iconStyle, 'background-repeat')
      this.$delete(this.iconStyle, 'border')
    }
  },
  created () {

  },
  mounted () {
    this.setStyles()
  }
}
</script>
<style>
  .layers-icon {
    width: 15px;
    height: 15px;
    border: 1px solid #DCDFE6;
    position: relative;
    display: inline-block;
    margin-right: 5px;
  }

  .layers-icon .point-icon {
    width: 4px;
    height: 4px;
    position: absolute;
  }

  .layers-icon .linestring-icon {
    height: 3px;
    width: 100%;
    position: absolute;
    top: 7px;
  }

  .layers-icon .linestring-dot-icon {
    width: 4px;
    height: 3px;
    position: absolute;
    top: 7px;
  }
</style>
